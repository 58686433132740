<template>
  <div class="editinfo">
    <div class="mycardbox">
      <my-card>
        <template #mycard>
          <h1>企业信息</h1>
          <editinfo-comps />
        </template>
      </my-card>
    </div>
  </div>
</template>
<script>
import MyCard from '../components/util/MyCard.vue';
import EditinfoComps from '../components/editinfo/EditinfoComps.vue';

export default {
  name: 'editinfo',
  components: { MyCard, EditinfoComps },
};
</script>
<style lang="scss" scoped>
.editinfo {
	position: relative;
	.mycardbox {
		position: relative;
		margin-bottom: 1px;
		.mycard {
			// border-bottom-left-radius: 0;
			// border-bottom-right-radius: 0;
			// border-top-left-radius: 0;
			// border-top-right-radius: 0;
			overflow: hidden;
			background: #fff;
		}
		h1 {
			padding: 10px 0 0 35px;
			color: $dark;
			font-weight: bold;
			border-bottom: 1px solid $light;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				left: 18px;
				bottom: 7px;
				width: 10px;
				height: 10px;
				background: $activebgcolor;
				transform: rotate(45deg);
			}
		}
	}
}
</style>
