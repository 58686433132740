import { ElMessage } from 'element-plus';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import myAxios from '../../../utils/axios/index_v1';
// import MenuListClass from '../../../store/class/menuListClass';
import QueryAuthClass from '../../../store/class/queryAuthClass';
// 资料修改类
export default class EditInfoClass {
  /**
   *
   * @param {*} modelType updateUserInfo|queryUserInfo
   * @param {*} editInfoDatas 编辑数据
   * @param {*} activeDatas  表单状态数据
   * @param {*} editInfoRules $ref
   */
  constructor(modelType, editInfoDatas, activeDatas, editInfoRules) {
    this.modelType = modelType;// [queryUserInfo,updateUserInfo]只限这两个值
    this.editInfoDatas = editInfoDatas;
    this.activeDatas = activeDatas; //
    this.editInfoRules = editInfoRules;// 验证规则数据
    this.router = useRouter();// 路由
    this.storeDatas = inject('storeDatas');
    this.setFilterOfContainer = inject('setFilterOfContainer');
    // this.MenuListClass = new MenuListClass(this.storeDatas, this.setFilterOfContainer, this.router);
    this.QueryAuthClass = new QueryAuthClass(this.storeDatas, this.setFilterOfContainer, this.router);
    this.verifyKey = ['user', 'attn', 'phone', 'company', 'address', 'customsID'];// 提交前需要验证的key（加红点)
    this.objName = { // 标注名称
      user: '用户名',
      attn: '联系人',
      phone: '手机',
      company: '公司名称',
      address: '公司地址',
      customsID: '海关注册码',
    };
  }

  // 更新用户信息
  static updateUserInfo = 'updateUserInfo'

  // 加载原始数据
  static queryUserInfo = 'queryUserInfo'

  loadRawData = () => {
    const that = this;
    if (![EditInfoClass.updateUserInfo, EditInfoClass.queryUserInfo].includes(this.modelType)) {
      ElMessage.error('参数错误');
      return;
    }
    myAxios().initAxios({
      method: 'POST',
      url: '/edit_info',
      data: {
        modelType: this.modelType, // 加载的是
        userInfo: this.editInfoDatas, // 发送的数据
      },
    })
      .then((res) => {
        if (Object.prototype.hasOwnProperty.call(res, 'currentURI')) {
          if (res.code === 200 && res.currentURI === EditInfoClass.queryUserInfo) {
            // 获取原始数据
            Object.assign(that.editInfoDatas, res.results);
          } else if (res.code === 200 && res.currentURI === EditInfoClass.updateUserInfo) {
            // 修改服务器数据
            this.changeCurrentStatus();
            ElMessage.success('修改成功');
          }
        }
      })
      .catch((err) => {
        if (!err.isLogin || !err.code === 1007) {
          this.QueryAuthClass.logOutFunc('请重新登陆');
        }
      });
  }

  // setRequiredRedStart 设置红点是否展示
  setRequiredRedStart = () => {
    Object.keys(this.editInfoRules).forEach((key) => {
      try {
        if (this.verifyKey.includes(key)) {
          this.editInfoRules[key][0].required = !this.activeDatas.isDisabled;
        }
      } catch (error) {
        console.error('设置红点出错');
      }
    });
  }

  /** verifyInputBeforeSubmit 提交前先校验数据
   *
   * @param {*} editInfoRefs ref节点
   * @returns true:验证通过  false:必填项有误
   */
  verifyInputBeforeSubmit = async (editInfoRefs) => {
    const errMsgObj = {};
    await editInfoRefs.value.validateField(this.verifyKey, (errMsg, info) => {
      if (info !== null) {
        errMsgObj[Object.keys(info)[0]] = errMsg;
      }
    });
    const keyAry = Object.keys(errMsgObj);
    if (keyAry.length > 0) { // 对象中有错误信息
      ElMessage.error(`${this.objName[keyAry[0]]}:${errMsgObj[keyAry[0]]}`);
      return false;
    }
    return true;// 通过验证
  }

  // changeCurrentStatus 变换当前状态（修改|保存）切换
  changeCurrentStatus = () => {
    this.activeDatas.isDisabled = !this.activeDatas.isDisabled; // 当前按钮可编辑状态
    this.setRequiredRedStart(); // 设置红点是否展示
  }

  // backUpEditInfoDatasVal 点击修改时备份editInfoDatas值
  // 用途：提交前先判断是否有修改
  backUpEditInfoDatasVal = () => {
    this.activeDatas.initDatas = JSON.stringify(Object.values(this.editInfoDatas));
  }

  /** checkChanged 比较原始值与修改的值是否有变化
   *
   * @returns true:有修改 ，false：无修改
   */
  checkChanged = () => {
    if (JSON.stringify(Object.values(this.editInfoDatas)) === this.activeDatas.initDatas) {
      // 没有变化
      ElMessage.success('当前未修改');
      return false;
    }
    return true;
  }
}
